import { DatesSetArg, EventClickArg, EventInput } from "@fullcalendar/core";
import nbLocale from '@fullcalendar/core/locales/nb';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import FullCalendar from '@fullcalendar/react';
import * as moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Network } from "../../../api/network";
import { CustomBreadCrumb } from "../../../components/shared/BreadCrumb";
import { Dialog } from "../../../core/dialog";
import { useOnEntityChange } from "../../../core/hooks/useOnEntityChange";
import { HourType } from "../../../models/shared/HourType";
import { SalaryItemEntity } from "../../../models/shared/SalaryItemEntity";
import RootStore from "../../../stores/root-store";
import { AddEventDialog } from "./AddEventDialog";
import "./Hours.tsx.scss";
import { createPopper } from "@popperjs/core/lib/createPopper";


const showAddDialog = (start: Date, end: Date) => {
  const dialog = new Dialog<{}>()
  dialog.buttons = [];
  dialog.title = "Timeføring";
  dialog.closable = true;
  dialog.content = (state, onClose) => <AddEventDialog onClose={onClose} start={start} end={end} />;
  RootStore.UIStore.addDialog(dialog);
}

export const EmployeeHours: React.FC = () => {

  const [period, setPeriod] = useState<DatesSetArg | null>(null);
  const [events, setEvents] = useState<Array<EventInput>>([]);
  const [holidays, setHolidays] = useState<Array<Date>>([]);
  const [vacationDays, setVacationDays] = useState<Array<Date>>([]);
  const [showLongEvents, setShowLongEvents] = useState(false);

  useOnEntityChange("SalaryItemEntity", () => loadHours());

  const onEventClick = (arg: EventClickArg) => {

    const removeFn = async (close: () => void, series: boolean) => {
      const network = new Network();
      await network.getSimple(`/api/salary/remove/${arg.event.id}/${series}`);
      close();
    }

    const dialog = new Dialog<{}>();
    dialog.title = "Slette oppføring";
    dialog.content = <p>Er du sikker på at du vil slette denne oppføringen?</p>;
    dialog.closable = true;
    dialog.buttons = [
      {
        onClick: (state, fnClose) => { removeFn(fnClose, false); },
        text: "Slett"
      },
      {
        onClick: (state, fnClose) => fnClose(),
        text: "Avbryt"
      }
    ];

    if (arg.event.groupId != "undefined") {
      dialog.buttons = [{
        onClick: (state, fnClose) => { removeFn(fnClose, true); },
        text: "Slett serie"
      }, ...dialog.buttons];
    }

    RootStore.UIStore.addDialog(dialog);
  }

  useEffect(() => {
    const io = async () => {
      if (period) {
        const network = new Network();
        const _vacationDays: Array<Date> = [];
        const _holidays: Array<Date> = [];
        const years = [...new Set([period.start.getFullYear(), period.end.getFullYear()])]
        for (const year of years) {
          const h = await network.getSimple<Array<string>>(`/api/lookup/holidays/${year}`);
          if (h)
            _holidays.push(...h.map(a => new Date(a)));
          const v = await network.getSimple<Array<string>>(`/api/lookup/vacationdays/${year}`);
          if (v)
            _vacationDays.push(...v.map(a => new Date(a)));
        }
        setHolidays(_holidays);
        setVacationDays(_vacationDays);
      }
    }
    io();
  }, [period])

  const loadHours = async () => {
    if (period) {
      const network = new Network();
      const result = await network.get<Array<SalaryItemEntity>>(
        `/api/salary/${moment(period.start).format("YYYY-MM-DD")}/${moment(period.end).format("YYYY-MM-DD")}`)
      if (result) {
        const events = result.map<EventInput>(r => {
          let type;
          let color;
          let alternate = false;
          if (r.Type === HourType.LeaveWithoutPay) {
            type = "Fravær uten lønn";
            color = "#fd7f6f";
            alternate = true;
          } else if (r.Type === HourType.LeaveWithPay) {
            type = "Fravær med lønn";
            color = "#7eb0d5";
            alternate = false;
          } else if (r.Type === HourType.Overtime) {
            type = "Overtid";
            color = "#beb9db";
            alternate = false;
          } else if (r.Type === HourType.Overtime100) {
            type = "Overtid 100%";
            color = "#d1cee5";
            alternate = false;
          } else if (r.Type === HourType.SelfCertification) {
            type = "Egenmelding";
            color = "#ffee65";
            alternate = true;
          } else if (r.Type === HourType.SickChild) {
            type = "Sykt barn";
            color = "#ffb55a";
            alternate = false;
          } else if (r.Type === HourType.SickLeave) {
            type = "Sykemelding";
            color = "#bd7ebe";
            alternate = false;
          } else if (r.Type === HourType.VacationDay) {
            type = "Ferie";
            color = "#b2e061";
            alternate = false;
          } else if (r.Type === HourType.Work || r.Type === HourType.ExtraHour) {
            type = "Arbeid";
            color = "#fdcce5";
            alternate = true;
          } else {//if (r.Type === HourType.Suspended) {
            type = "Permitert";
            color = "#999999";
            alternate = false;
          }

          if (r.Hours)
            type += " (" + r.Hours + "t)";
          return {
            id: r.Id.toString(),
            start: new Date(r.Date!),
            end: moment(new Date(r.Date!)).add(1, 'day').toDate(),
            textColor: alternate ? "#2b2b2b" : undefined,
            backgroundColor: color,
            allDay: true,
            title: `${r.EmployeeName} - ${type}`,
            editable: true,
            groupId: r.Series ?? undefined,
            extendedProps: {
              "type": r.Type
            }
          };
        });
        setEvents(events);
      }
    }
  }

  useEffect(() => {
    loadHours();
  }, [period]);


  const allEvents = events.filter(r => (r.extendedProps!.type != HourType.Suspended && r.extendedProps!.type != HourType.SickLeave) || showLongEvents).concat(vacationDays.map<EventInput>(v => ({
    start: v,
    end: v,
    allDay: true,
    display: 'background',
    backgroundColor: '#b1ffb380'
  })).concat(holidays.map<EventInput>(v => ({
    start: v,
    allDay: true,
    display: 'background',
    backgroundColor: '#fb00ff33' //'#e4ff0033'
  }))));

  return <div className="page-employees-hours">
    <h1>Timeføring</h1>
    <CustomBreadCrumb links={[{ title: "Økonomi", path: null }, { title: "Timeføring", path: null }]} />
    <div className="panel form-container">
      <div className="calendar">
        <FullCalendar
          locale={nbLocale}
          datesSet={setPeriod}
          aspectRatio={2}
          height="auto"
          dayHeaderFormat={{ weekday: 'long' }}
          weekNumbers={true}
          weekNumberCalculation="ISO"
          weekNumberFormat={{ week: "numeric" }}
          titleFormat={{ year: 'numeric', month: 'long' }}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          selectable={true}
          select={(info) => showAddDialog(info.start, info.end)}
          events={allEvents}
          eventClick={onEventClick}
          customButtons={
            {
              test: {
                text: showLongEvents ? 'Viser sykemeldinger/permisjoner' : 'Skjuler sykemeldinger/permisjoner',
                click: () => setShowLongEvents(!showLongEvents),
              }
            }
          }
          headerToolbar={
            {
              start: 'title', // will normally be on the left. if RTL, will be on the right
              center: '',
              end: 'test today prev,next' // will normally be on the right. if RTL, will be on the left
            }
          }
          eventDidMount={info => {
            info.el.title = info.event.title
          }}
        />
      </div>
    </div>
  </div>
}