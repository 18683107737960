import { WorkOrderDetailsUnitModel } from "../models/shared/WorkOrderDetailsUnitModel";
import { PaintType } from "../models/shared/PaintType";
import { ClientFileReferenceModel } from "../models/ClientFileReferenceModel";
import { FileReferenceModel } from "../models/shared/FileReferenceModel";
import { ImageSize } from "../models/shared/ImageSize";
import { CorrosivityCategory } from "../models/shared/CorrosivityCategory";

/**
 * Adds to nullable numbers
 * @param a a number or null
 * @param b a number or null
 */
export const add = (a?: number, b?: number) => (a || 0) + (b || 0);

/**
 * Calculates the volumn for a unit in cubic meters.
 * @param unit A unit with a width, height and length.
 */
export const volumForUnit = (unit: WorkOrderDetailsUnitModel) => (unit.Width || 0) * (unit.Height || 0) * (unit.Length || 0) / 1000000;

export function canUseKeyboard(event: KeyboardEvent) {
  if (event.target) {
    const element = event.target as HTMLElement;
    if (element.tagName == "INPUT")
      return false;
  }
  return true;
}

export function createKeybordEnterAndSpaceAction(onPress: (event: React.KeyboardEvent<HTMLElement>) => void) {
  return {
    onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key == "Enter" || event.key == " " || event.key == "Spacebar") {
        event.nativeEvent.preventDefault();
        onPress(event);
      }
    }
  }
}
export function forward(currentPosition: number, length: number) {
  if (currentPosition == length - 1)
    return 0;
  else
    return currentPosition + 1;
}

export function back(currentPosition: number, length: number) {
  return currentPosition == 0 ? length - 1 : currentPosition - 1;
}

export function entityNameToControllerName(entityName: string) {

  // Remove full name
  const withoutNameSpace = entityName.split('.').splice(-1)[0];

  // Remove Entity and add an s (WorkOrderEntity => workorders)
  return withoutNameSpace.slice(0, -6).toLowerCase() + "s";
}

export function isNullOrEmpty(value?: string) {
  if (value == null)
    return true;
  if (typeof value !== "string")
    return true;
  if (value.trim().length == 0)
    return true;
  return false;
}

export function toCountry(code?: string) {
  if (code != null) {
    if (code.toLowerCase() == "se")
      return "Sverige";
    else if (code.toLowerCase() == "dk")
      return "Danmark";
  }
  return "Norge";
}

export function showRalAndGloss(paintType?: PaintType) {

  if (paintType == null)
    return false;

  return paintType == PaintType.Powder || paintType == PaintType.Wet;
}

export function onEnter(fn: (element?: HTMLInputElement) => any): React.KeyboardEventHandler<HTMLInputElement> {
  return (event) => {
    if (fn && event.key == "Enter") {
      event.preventDefault();
      fn(event.currentTarget);
    }
  }
}

export async function downloadFile(url: string, filename: string) {

  const response = await fetch(url);
  const blob = await response.blob();

  const objectUrl = window.URL.createObjectURL(blob);

  const element = document.createElement("a");
  element.style.display = "none";
  element.href = objectUrl;
  element.download = filename;
  document.body.appendChild(element);
  element.click();

  window.URL.revokeObjectURL(objectUrl);
  document.body.removeChild(element);
}

export function isMobile() {
  const mq = window.matchMedia("(max-width: 767px)");
  if (mq.matches)
    return true;
  else
    return false;
}

export function isUpToPC() {
  const mq = window.matchMedia("(max-width: 1365px)");
  if (mq.matches)
    return true;
  else
    return false;
}

export function getImageUrl(image: FileReferenceModel | ClientFileReferenceModel | string, size: ImageSize) {
  let id;
  if (typeof image === "string")
    id = image;
  else
    id = image.Id;

  return `/api/lookup/file/${id}/${size}`
}

export function paintDescription(paintType?: PaintType, ralCode?: string) {
  if (paintType == null || paintType == PaintType.None)
    return "Ingen";
  else if (paintType == PaintType.ByCustomer)
    return "Av kunde";
  else if (paintType == PaintType.Powder)
    return "Pulverlakkering " + ralCode;
  else if (paintType == PaintType.Wet)
    return "Våtlakkering " + ralCode;
}

export function getScreenSize(width: number = window.innerWidth) {
  if (width >= 1920)
    return ScreenSize.PC;
  if (width >= 1366)
    return ScreenSize.Laptop;
  if (width >= 768)
    return ScreenSize.Tablet;
  return ScreenSize.Mobile;
}

export enum ScreenSize {
  PC,
  Laptop,
  Tablet,
  Mobile
}

export function getCorrosivityName(category: CorrosivityCategory) {
  if (category == CorrosivityCategory.CX)
    return "CX";
  else
    return `C${category}`;
}