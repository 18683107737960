import * as React from "react";
import { FileReferenceModel } from "ts/models/shared/FileReferenceModel";
import "./UnitImagesDialog.tsx.scss";
import WeightIcon from "svg/weight.svg";
import DeleteIcon from "svg/trash.svg";
import { Button } from "ts/components/shared/Button";
import { Dialog } from "ts/core/dialog";
import { WeightDialog } from "../units/WeightDialog";
import { default as RootStore } from "../../../stores/root-store";
import { GenericDataStore } from "ts/stores/genericData-store";
import { WorkOrderDetailsModel } from "ts/models/shared/WorkOrderDetailsModel";
import { observer } from "mobx-react";
import { computed } from "mobx";
import HookIcon from "svg/hook.svg";
import { HookUnitDialogComponent } from "./HookDialog";
import { Direction } from "../../../models/shared/Direction";
import { downloadFile, getImageUrl } from "../../../core/util";
import { ImageSize } from "../../../models/shared/ImageSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Network } from "../../../api/network";

interface Props {
  unitId: number;
  selected: string;
  store: GenericDataStore<WorkOrderDetailsModel>;
}

interface State {
  selected: string | undefined;
  fullSize: boolean;
}

@observer
export class UnitImagesDialog extends React.Component<Props, State> {
  refStore: Map<string, React.RefObject<HTMLDivElement>>;

  @computed get unit() {
    if (this.props.store.entity == null)
      return undefined;
    return this.props.store.entity.Units.find(u => u.Id === this.props.unitId);
  }

  constructor(props: Props) {
    super(props);
    this.state = { selected: this.props.selected, fullSize: false };
    window.addEventListener("keydown", this.processKeyDown);
    this.refStore = new Map<string, React.RefObject<HTMLDivElement>>();
  }

  onSelect = (id: string) => {
    this.setState({ selected: id });
  }

  toggleFullSize = () => {
    this.setState(state => ({ fullSize: !state.fullSize && state.selected != null }));
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.processKeyDown);
  }

  scrollIntoView(id: string) {
    setTimeout(() => {
      const element = this.refStore.get(id);
      if (element && element.current)
        element.current.scrollIntoView({ behavior: "smooth", inline: "center" });
    }, 0);
  }

  processKeyDown = (e: KeyboardEvent) => {
    if (this.state.selected == null || this.unit == null)
      return;

    // Space 
    if (e.key == " ") {
      e.preventDefault();
      this.toggleFullSize()
      return;
    }

    // Delete
    if (e.key == "Delete" && this.state.selected) {
      this.delete(this.state.selected);
      return;
    }


    // ArrowRight
    const { Images, ErrorImages, ExtraWorkImages } = this.unit;
    const current = [Images, ErrorImages, ExtraWorkImages].find(e => e != null && e.some(e => e.Id === this.state.selected));
    if (current == null)
      return;

    let currentIndex = current.findIndex(c => c.Id === this.state.selected) || 0;

    const setFirst = (lst: Array<Array<FileReferenceModel> | undefined>) => {
      const el = lst.find(l => l != null && l.length > 0);
      if (el) {
        this.setState({ selected: el[0].Id });
        this.scrollIntoView(el[0].Id);
      }
    }

    if (e.key == "ArrowRight") {
      const id = current[++currentIndex % current.length].Id;
      this.setState({ selected: id });
      this.scrollIntoView(id);
    }
    else if (e.key == "ArrowLeft") {
      const id = current[(currentIndex + current.length - 1) % current.length].Id;
      this.setState({ selected: id });
      this.scrollIntoView(id);
    }
    else if (e.key == "ArrowUp") {
    }
  }

  delete = async (id: string) => {

    const dialog = new Dialog<{}>()
    dialog.buttons = [{
      text: "OK",
      onClick: async (state, fnClose) => {
        await this.props.store.deleteImage(this.props.unitId, id)
        this.setState({ selected: undefined });
        fnClose();
      }
    },
    { text: "Avbryt", onClick: (state, fnClose) => fnClose() }
    ];
    dialog.title = "Slette bilde";
    dialog.closable = true;
    dialog.content = (state) => <p>{`Er du sikker at du vil slette valgt bilde?`}</p>;
    RootStore.UIStore.addDialog(dialog);
  }

  manageWeights = () => {
    const dialog = new Dialog<any>();
    dialog.title = "Vekter";
    dialog.closable = true;
    dialog.content = <WeightDialog store={this.props.store} unitId={this.props.unitId} />
    dialog.buttons = [{
      onClick: (state, fnClose) => fnClose(),
      text: "Lukk"
    }];

    RootStore.UIStore.addDialog(dialog);
  }

  hookUnit = () => {
    const dialog = new Dialog<any>();
    dialog.title = "Oppheng";
    dialog.closable = true;
    dialog.content = <HookUnitDialogComponent unitId={this.props.unitId} />
    dialog.buttons = [{
      onClick: (state, fnClose) => fnClose(),
      text: "Lukk"
    }];
    RootStore.UIStore.addDialog(dialog);
  }

  async download() {
    const allImages = [...(this.unit?.Images ?? []), ...(this.unit?.ErrorImages ?? []), ...(this.unit?.ErrorImages ?? [])];
    const selectedImage = allImages.find(i => i.Id === this.state.selected);
    await downloadFile(`/api/lookup/file/${this.state.selected}/${ImageSize.Original}`, selectedImage?.Name ?? "image.jpeg");
  }

  findImage = (selected: string) => {
    const images = [...this.unit?.Images ?? [], ...this.unit?.ErrorImages ?? [], ...this.unit?.ExtraWorkImages ?? []];
    const img = images.find(i => i.Id === selected);
    return img;
  }

  getMediaElement = () => {
    const img = this.findImage(this.state.selected!);
    if (img?.ContentType?.toLowerCase().startsWith("video")) {
      return <video aria-label={img.Name} src={getImageUrl(img, ImageSize.Original)} autoPlay controls muted loop />
    } else {
      return <div
        role="img"
        onClick={e => this.toggleFullSize()}
        style={{ backgroundImage: `url("${getImageUrl(this.state.selected!, ImageSize.Desktop)}")` }} />
    }
  }

  render() {

    if (this.unit == null)
      return null;

    const isCustomer = RootStore.UserStore.isCustomer;

    const { Images, ErrorImages, ExtraWorkImages } = this.unit;
    return <div className="unit-images-dialog">
      <div className="unit-images-dialog-current">
        {this.getMediaElement()}
        {!isCustomer && <div className="unit-images-dialog-actions details-action">
          {this.unit.Direction == Direction.Incoming && <Button text="Oppheng" onClick={() => this.hookUnit()} icon={HookIcon}></Button>}
          {this.unit.Direction == Direction.Outgoing && <Button text="Vekter" onClick={() => this.manageWeights()} icon={WeightIcon}></Button>}
          <Button text="Last ned bilde" onClick={() => this.download()} icon={<FontAwesomeIcon icon={faDownload} />}></Button>
          {this.state.selected != null && <Button text="Slett bilde" onClick={() => this.delete(this.state.selected!)} icon={DeleteIcon}></Button>}
        </div>}
      </div>
      {!this.state.fullSize && <div className="unit-images-dialog-selector">
        <ImageList images={Images} text="Svart" onSelect={this.onSelect} selected={this.state.selected} refStore={this.refStore} />
        {ErrorImages != null && ErrorImages.length > 0 && <ImageList images={ErrorImages} text="Feil" onSelect={this.onSelect} selected={this.state.selected} refStore={this.refStore} />}
        {ExtraWorkImages != null && ExtraWorkImages.length > 0 && <ImageList images={ExtraWorkImages} text="Arbeid" onSelect={this.onSelect} selected={this.state.selected} refStore={this.refStore} />}
      </div>}
    </div>
  }
}



interface ImageListProps {
  images?: Array<FileReferenceModel>;
  text: string;
  onSelect: (imageId: string) => void;
  selected: string | undefined;
  refStore: Map<string, React.RefObject<HTMLDivElement>>;
}

@observer
class ImageList extends React.Component<ImageListProps> {

  private scrollRef: React.RefObject<HTMLDivElement>;
  private clickStartPosition: number = 0;
  private listStartPosition: number = 0;
  private dragging: boolean = false;

  constructor(props: ImageListProps) {
    super(props);
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    const element = this.scrollRef.current;
    if (element != null) {
      element.addEventListener("mousedown", e => {
        e.preventDefault();
        this.dragging = true;
        this.clickStartPosition = e.clientX;
        this.listStartPosition = element.scrollLeft;
      });

      element.addEventListener("mousemove", e => {
        if (this.dragging) {
          const offset = this.clickStartPosition - e.clientX;
          element.scroll(this.listStartPosition + offset, element.scrollTop);
        }
      });

      element.addEventListener("mouseup", e => {
        this.dragging = false;

        // if we have dragged (more than 5px), prevent clicking
        if (Math.abs(this.clickStartPosition - e.clientX) > 5)
          window.addEventListener("click", captureClick, true);
      });

      element.addEventListener("mouseleave", () => this.dragging = false);
    }
  }

  onWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const width = e.currentTarget.querySelector("div[role='img']")!.clientWidth;
    const offset = e.deltaY < 0 ? -1 : 1;
    e.currentTarget.scrollBy(width * offset, 0);
  }

  isVideo = (model: FileReferenceModel) => model.ContentType?.toLowerCase().startsWith("video");

  render() {
    const { images, text, onSelect, selected, refStore } = this.props;

    if (images == null)
      return null;

    const getRef = (id: string) => {
      if (!refStore.has(id))
        refStore.set(id, React.createRef());
      return refStore.get(id);
    }

    return <div className="unit-images-dialog-selector-item">
      <span className="unit-images-dialog-label">{text}</span>
      <div className="unit-images-dialog-selector-container" ref={this.scrollRef} onWheel={this.onWheel}>
        <ul >
          {images.map(i => <li key={i.Id}>
            {!this.isVideo(i) && <div
              ref={getRef(i.Id)}
              role="img"
              style={{ backgroundImage: `url("${getImageUrl(i, ImageSize.Preview)}")` }}
              aria-label={i.Name}
              aria-selected={selected == i.Id}
              tabIndex={0}
              onClick={() => onSelect(i.Id)} />}
            {this.isVideo(i) && <video aria-label={i.Name} aria-selected={selected == i.Id} src={getImageUrl(i, ImageSize.Original)} onClick={() => onSelect(i.Id)} tabIndex={0} muted autoPlay /> }
          </li>)}
        </ul>
      </div>
    </div>
  }
}

function captureClick(event: MouseEvent) {
  event.stopPropagation();
  window.removeEventListener("click", captureClick, true);
}