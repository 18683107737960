import * as React from 'react';
import { DialogListComponent } from '../components/shared/dialog';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../stores/root-store';
import { Login } from '../components/login';
import { Header } from '../components/layout/Header';
import { Footer } from '../components/layout/Footer';
import "./viewport.scss";
import { Switch, Route, withRouter, RouteComponentProps, RouteProps } from 'react-router';
import { WorkOrderPage } from './views/workorders/WorkOrderPage';
import { Customers } from './views/customers/Customers';
import { Users } from './views/users/Users';
import { OrderPage } from './views/orders/OrderPage';
import { AddressPage } from './views/addresses/AddressPage';
import { ArticlePage } from './views/articles/ArticlePage';
import { BoomPage } from './views/booms/BoomPage';
import { RushRequestsList } from './views/rush/RushRequestsList';
import { TransportContainer } from './views/transport/TransportContainer';
import { NotFound } from '../components/NotFound';
import classNames from 'classnames';
import { OfferPage } from './views/offers/OfferPage';
import { default as RootStore } from "../stores/root-store";
import { SearchPage } from './views/search/SearchPage';
import { Welcome } from './views/index/index';
import { PageAlertsContainer } from './views/pagealerts/PageAlertsContainer';
import { PageAlertsBar } from '../components/shared/PageAlertsBar';
import { FinancePage } from './views/finances/FinancePage';
import { ComplaintContainer } from './views/complaint/ComplaintContainer';
import { Role } from '../models/shared/Role';
import { StatisticsOverview } from './views/statistics/StatisticsOverview';
import { Menu2 } from '../components/layout/menu/menu2';
import { KeyFiguresPage } from './views/statistics/keyfigures/keyfigurespage';
import { MissingPhonenumberDialog } from '../components/dialogs/MissingPhonenumberDialog';
import { Toaster } from 'react-hot-toast';
import { CalculateOffer } from './views/offers/CalculateOffer';
import { Dashboard } from './views/index/dashboard/dashboard';
import { EmployeeHours } from './views/employees/Hours';
import { EmployeePayment } from './views/employees/Payment';
import { History } from 'history';

interface S {
  altPressed: boolean;
}

@inject(stores => ({ ...(stores as object) }))
@observer
class App extends React.Component<Partial<IRootStore> & RouteComponentProps<any>, S> {

  state = { altPressed: false }

  render() {

    const { requiresAuthentication, isConnected } = this.props.UserStore!;

    if (requiresAuthentication == null || requiresAuthentication == true)
      return (
        <>
          <div>
            <Login />
          </div>
          <DialogListComponent />
        </>
      );

    return (
      <>
        <div className={classNames("viewport", { "alt-is-pressed": this.state.altPressed })}>
          <Header />
          <Menu2 />
          <PageAlertsBar />
          <div className="workspace">
            <main>
              <Switch>
                <Route path="/workorders" render={p => <WorkOrderPage route={p} />} />
                <Route path="/customers" render={p => <Customers route={p} />} />
                <Route path="/users" render={p => <Users route={p} />} />
                <Route path="/orders" render={p => <OrderPage route={p} />} />
                <PrivilegedRoute exact path="/offers/calculate" render={p => <CalculateOffer />} />
                <PrivilegedRoute path="/offers" render={p => <OfferPage route={p} />} />
                <Route path="/addresses" render={p => <AddressPage route={p} />} />
                <PrivilegedRoute path="/articles" render={p => <ArticlePage route={p} />} />
                <PrivilegedRoute path="/booms" render={p => <BoomPage route={p} />} />
                <Route path="/rushrequests" render={p => <RushRequestsList route={p} />} />
                <PrivilegedRoute path="/search/:phrase" render={p => <SearchPage {...p} />} />
                <PrivilegedRoute path="/transport" render={p => <TransportContainer {...p} />} />
                <PrivilegedRoute path="/pagealerts" render={p => <PageAlertsContainer route={p} />} />
                <PrivilegedRoute path="/finance" roles={[Role.Administrator]} render={p => <FinancePage route={p} />} />
                <PrivilegedRoute path="/complaints" render={p => <ComplaintContainer {...p} />} />
                <PrivilegedRoute path="/statistics" exact render={p => <StatisticsOverview />} />
                <PrivilegedRoute path="/statistics/keyfigures" render={p => <KeyFiguresPage />} />
                <PrivilegedRoute path="/employees/hours" render={p => <EmployeeHours />} />
                <PrivilegedRoute path="/employees/payslips" render={p => <EmployeePayment />} />
                <Route path="/" render={p => <Dashboard />} />
                <Route path="*"><NotFound /></Route>
              </Switch>
            </main>
          </div>
          <Footer />
        </div>
        <MissingPhonenumberDialog />
        <DialogListComponent />
        <Toaster position={'bottom-right'} />
        <ScrollToTop history={this.props.history} />
      </>);
  }
}

export default withRouter(App);

const ScrollToTop: React.FC<{ history: History<unknown> }> = ({ history }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname])
  return null;
}

interface PrivilegedRouteProps extends RouteProps {
  roles?: Array<Role>;
}

export function PrivilegedRoute(props: PrivilegedRouteProps) {
  const requestedRoles = props.roles ?? [Role.Administrator, Role.Employee, Role.EmployeeAdmin];
  const userRole = RootStore.UserStore.role;
  const hasRole = userRole !== undefined && requestedRoles.some(req => req === userRole);

  if (!hasRole)
    return null;

  return <Route {...props} />;
}